<template>
<div class="flex xs:block">
  <div
    v-for="(link, index) in links"
    :key="index"
    class="flex xs:block md:block text-gray-900 dark:text-white dark:text-white hover:underline  mx-2">

   <router-link :to="'/' + link.slug">{{link.name}}</router-link>
  </div>
</div>
</template>

<script>
export default {
  name: "MenuItemFooter",
  data() {
    return {
      open: false,
      links: [
        {
          name: "Add coin",
          slug: "addcoin",
          //route: () => this.$router.push({ name: "AddCoin" }),
        },
        {
          name: "Promote",
          slug: "promote",
          //route: () => this.$router.push("/promote"),
        },
        {
          name: "DYOR",
          slug: "dyor"
        },
         {
          name: "Terms & Conditions",
          slug: "terms-and-conditions"
          //route: () => this.$router.push("/promote"),
        },
        {
          name: "Privacy Policy",
          slug: "privacy-policy"
         // route: () => this.$router.push("/promote"),
        },
        {
          name: "Contact Us",
          slug: "contact-us"
          //route: () => this.$router.push("/promote"),
        },
      ],
    };
  },
  computed: {},
};
</script>

<style></style>