<template>
  <div class=" max-w-7xl mx-auto">
    <Promoted />
  </div>

  <div class="max-w-7xl mx-auto">
    <Best />
  </div>
</template>

<script>
import Promoted from "../components/Cards/Promoted.vue";
import Best from "../components/Cards/Best.vue";

export default {
  name: "Home",
  components: {
    Promoted,
    Best,
  },
};
</script>
