 <template>
 <div v-if="this.$route.path !== '/'"
          class="
            mt-6
            flex flex-col-reverse
            justify-stretch
            space-y-4 space-y-reverse
            sm:flex-row-reverse
            sm:justify-end
            sm:space-x-reverse sm:space-y-0 sm:space-x-3
            md:mt-0
            md:flex-row
            md:space-x-3
          "
        >
          <router-link
            to="/"
            class="
              inline-flex
              items-center
              justify-center
              px-4
              py-2
              dark:text-white
            "
          >
            <i class="fa fa-arrow-left dark:text-white text-lg mr-3"></i> Back To Home
          </router-link>
        </div>

</template>

<script>
export default {
 name: "Back"
};
</script>

<style></style>