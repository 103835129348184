<template>
  <div v-if="showRecaptcha">
    <div
      class="fixed inset-0 w-full h-full flex items-center justify-center z-10"
    >
      <vue-recaptcha
        class="z-20"
        siteKey="6LdTcKcbAAAAALPXD5WhCzOV-1INg5p6sL8lMm_2"
        size="normal"
        theme="light"
        :tabindex="0"
        @verify="recaptchaVerified"
        @expire="recaptchaExpired"
        @fail="recaptchaFailed"
        ref="vueRecaptcha"
      >
      </vue-recaptcha>
      <div
        class="fixed inset-0 w-full h-full bg-green-900 opacity-60 z-10 pointer-events-none"
      ></div>
    </div>
  </div>
</template>

<script>
import vueRecaptcha from "vue3-recaptcha2";

export default {
  name: "app",
  props: ['showRecaptcha'],
  components: {
    vueRecaptcha,
  },
  methods: {
    recaptchaVerified(token) {
        this.$emit('recaptchaVerified', token)
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {},
  },
};
</script>
