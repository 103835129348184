<template>
<div class="bradcrumbs pt-10 px-3 flex justify-between items-center">
  <ol class="flex text-gray-700 dark:text-gray-300  ">
    <li class="px-2"><router-link to="/" class="hover:underline"> Home </router-link></li>
    <li class="text-gray-500 dark:text-gray-100 select-none">/</li>
    <li class="px-2" v-if="currentRouteName !== 'Home'"> {{currentRouteName}} </li>
    <li class="text-gray-500 dark:text-gray-100 select-none" v-if="currentRouteName !== 'Home'">/</li>
    <li class="px-2"> {{currentRouteDynamicName}}  </li>
 
  </ol>

       <div>

 
 <Back />

     </div> 

</div>
</template>

<script>
 import Back from "./Back.vue";
export default {
 name: "Breadcrumbs",
  components: {
    Back,
  },
 computed: {
    
    currentRouteName() {
     
        return this.$route.name;
     
    },
     currentRouteDynamicName() {
        return this.$route.params.coinId;
    }
} 
};
</script>

<style></style>