<template>
  <div class="pt-5 pb-10">
    <div>
      <div class="">
        <nav
          class="relative z-0 flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          <div class="shadow-sm cursor-pointer text-gray-900 dark:text-white rounded-t-lg group relative
                      sm:max-w-xs flex-1 overflow-hidden bg-white hover:bg-gray-100 dark:bg-gray-900 dark:hover:bg-gray-700
                      py-4 px-4 text-lg font-medium text-center focus:z-10 font-bold">
            <span>Promoted Coins</span>
            <span
              aria-hidden="true"
              class="bg-green-500 absolute inset-x-0 bottom-0 h-0.5"
            ></span>
          </div>
        </nav>
      </div>
    </div>
    <DataTable :coins="coins" />
  </div>
</template>

<script>
import DataTable from "./DataTable.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    DataTable,
  },
  computed: {
    ...mapGetters(["coinsData"]),
    coins() {
      return this.coinsData
        .filter((coin) => {
          if (coin.promoted === 1) {
            return coin;
          }
        })
        .sort((a, b) => b.votes - a.votes);
    },
  },
};
</script>

<style></style>
