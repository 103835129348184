<template>
  <div class="pt-5 pb-10">
    <div>
 
        <nav
          class="relative z-0 flex flex-wrap"
          aria-label="Tabs"
        >
          <div
            @click="selectedTab = 0"
            class="
              cursor-pointer
              text-gray-900 dark:text-white
              bg-white hover:bg-gray-100 dark:bg-gray-900 dark:hover:bg-gray-700
              rounded-tl-lg
              group
              relative
              max-w-xs
              flex-1
              overflow-hidden
              bg-white
              py-4
              px-4
              text-lg
              font-medium
              text-center
              focus:z-10
              font-bold
              shadow-sm
            "
          >
            <span>All Time Best</span>
            <span
              aria-hidden="true"
              :class="
                `${
                  selectedTab === 0 ? 'bg-green-500' : 'bg-green-100'
                } absolute inset-x-0 bottom-0 h-0.5`
              "
            ></span>
          </div>
      
      <!-- 
          <div
            @click="selectedTab = 1"
            class="
            shadow-sm
              cursor-pointer
              text-gray-900 dark:text-white
              rounded-tr-lg
              group
              relative
              max-w-xs
              flex-1
              overflow-hidden
              bg-white hover:bg-gray-100 dark:bg-gray-900 dark:hover:bg-gray-700
              py-4
              px-4
              text-lg
              font-medium
              text-center
              hover:bg-gray-50
              focus:z-10
              font-bold
            "
          >
            <span>Today's Best</span>
            <span
              aria-hidden="true"
              :class="
                `${
                  selectedTab === 1 ? 'bg-green-500' : 'bg-green-100'
                } absolute inset-x-0 bottom-0 h-0.5 `
              "
            ></span>
          </div>
   -->
    
        </nav>
   
    </div>
    <DataTable v-if="coins.length > 0" :coins="coins" />
  </div>
</template>

<script>
import DataTable from "./DataTable.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      selectedTab: 0,
    };
  },
  computed: {
     ...mapGetters(["coinsData"]),
    coinStatus() {
      return this.selectedTab === 0 ? "all time best" : "today's best";
    },
    coins() {
      return this.coinsData
        .filter((coin) => {
         /* if (coin.status === this.coinStatus) {
            return coin;
          } */
          return coin;
        })
        .sort((a, b) => b.votes - a.votes);
    },
  },
};
</script>

<style></style>
