<template>
<div class="flex"> 
  <div v-for="(link, index) in links" :key="index" 
  class="text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white px-3 mx-3 py-2 rounded-md font-medium cursor-pointer" 
  @click="link.route">
    {{ link.name }}
  </div>
</div>
</template>

<script>
export default {
  name: "MenuItem",
  data() {
    return {
      open: false,
      links: [
        {
          name: "Add coin",
          route: () => this.$router.push({ name: "AddCoin" }),
        },
        {
          name: "Promote",
          route: () => this.$router.push("/promote"),
        },
        {
          name: "DYOR",
          route: () => this.$router.push("/dyor"),
        },
      ],
    };
  },
  computed: {},
};
</script>

<style></style>