 
import { createApp } from 'vue'
import store from './store/store'
import { VueCookieNext } from 'vue-cookie-next'
import App from './App.vue'
import router from './router'
import './index.css'
import './firebase'
import {Clipboard} from 'v-clipboard'
import { createMetaManager } from "vue-meta";
import VueMeta from 'vue-meta'
//createApp(App).use(router).use(store).use(VueCookieNext).use(Clipboard).use(VueTailwind).mount('#app')

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueCookieNext)
app.use(Clipboard)
app.use(createMetaManager())
app.mount('#app')
/*
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
  })
*/  