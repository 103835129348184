import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBOti0ehilfcLr-ohm36snIu8OhHZfFedg",
  authDomain: "coinlist2-f8441.firebaseapp.com",
  databaseURL: "https://coinlist2-f8441-default-rtdb.firebaseio.com",
  projectId: "coinlist2-f8441",
  storageBucket: "coinlist2-f8441.appspot.com",
  messagingSenderId: "322284138666",
  appId: "1:322284138666:web:42bc39b5dedd8cbd87e0d3",
  measurementId: "G-L6QWHL3B46"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const database = firebase.database()

export default database

