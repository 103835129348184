import axios from "axios";
import publicIp from "public-ip";

class Utils {
  serverBaseUrl = "https://verify.coinsurf.cc/siteVerify";
  //serverBaseUrl = "https://verify.coinsurf.cc/siteVerify";
  siteKey = "6LdTcKcbAAAAADSBsbEWhIDxH4prcJFFL_k14gLV";

  verifySite = async (token) => {
    let data = {
      response: token,
      remoteip: await publicIp.v4(),
    };
    let verification = await axios
      .post(this.serverBaseUrl, data)
      .then((response) => {
        return response.data.success;
      })
      .catch(() => {
        return false;
      });
    return verification;
  };
}


export const utils = new Utils();
