import { createStore } from "vuex";
import firebase from "firebase";
import database from "../firebase";
import data from "../api/data.json";
import publicIp from "public-ip";
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

const checkBlacklist = (blacklists, coinId, userEmail) => {
    let blacklist = blacklists.filter((blacklist) => {
        if (blacklist.coinId === coinId && blacklist.userEmail === userEmail) {
            return blacklist;
        }
    });
    let today = new Date().getTime();
    let expiry =
        blacklist.length != 0 ? new Date(blacklist[0].expiry).getTime() : 0;
    if (today >= expiry) {
        return false;
    } else {
        return true;
    }
};

const store = createStore({
    state() {
        return {
            isAuthenticated: false,
            user: {
                email: "",
                password: "",
            },
            coins: [],
            blacklists: [],
        };
    },
    getters: {
        authState(state) {
            return state.isAuthenticated;
        },
        coinsData(state) {
            return state.coins;
        },
        verifiedCoins(state) {
            return state.blacklists.filter((blacklist) => {
                if (blacklist.userEmail === state.user.email) {
                    return blacklist;
                }
            });;
        },
    },
    mutations: {
        LOGIN(state, payload) {
            state.isAuthenticated = true;
            const user = {
                email: payload.email,
                password: payload.password,
            };
            state.user = user;
        },
        LOGOUT(state) {
            state.isAuthenticated = false;
            state.user = {
                email: "",
                password: "",
            };
        },
    },
    actions: {
        async FIREABASE_LOGIN({ commit, dispatch }, payload) {
            firebase
                .auth()
                .signInWithEmailAndPassword(payload.email, payload.password)
                .then((data) => {
                    commit("LOGIN", payload);
                    payload.vm.$cookie.setCookie(
                        "user",
                        {
                            email: payload.email,
                            password: payload.password,
                        },
                        { expire: "1d" }
                    );
                    dispatch("FETCH_COINS");
                    dispatch("FETCH_BLACKLISTS");
                    //alert("Login Success");
                    createToast("Login Success", { type: 'success' })
                    payload.vm.$router.push("/");
                })
                .catch((error) => {
                    //alert(error.message);
                    createToast(error.message, { type: 'danger' })
                });
        },
        async FIREABASE_REGISTER({ }, payload) {
            firebase
                .auth()
                .createUserWithEmailAndPassword(payload.email, payload.password)
                .then(() => {
                    createToast("Registration Success", { type: 'success' })
                    //alert("Registration Success");
                    payload.vm.$router.push("/login");
                })
                .catch((error) => {
                    //alert(error.message);
                    createToast(error.message, { type: 'danger' })
                    payload.vm.$router.push("/login");
                });
        },
        async FIREABASE_LOGIN_GOOGLE({ commit, dispatch }, payload) {
            firebase
                .auth()
                .signInWithPopup(new firebase.auth.GoogleAuthProvider())
                .then((response) => {
                    localStorage.setItem('credentials', JSON.stringify(response.credential))
                    commit("LOGIN", {
                        email: response.user.email,
                        password: "",
                    });
                    payload.vm.$cookie.setCookie(
                        "user",
                        {
                            email: response.user.email,
                            password: "",
                        },
                        { expire: "1d" }
                    );
                    dispatch("FETCH_COINS");
                    dispatch("FETCH_BLACKLISTS");
                    //alert("Login Success");
                    createToast("Login Success", { type: 'success' })
                    payload.vm.$router.push("/");
                })
                .catch((error) => {
                    if (window.location.pathname == "/Login") {
                        //alert(error.message);
                        createToast(error.message, { type: 'danger' })
                    }
                });
        },
        async FIREABASE_AUTO_LOGIN_GOOGLE({ state, commit, dispatch }, payload) {
            var credentials = firebase.auth.GoogleAuthProvider.credential(payload.credentials.oauthIdToken, payload.credentials.oauthAccessToken)
            firebase
                .auth()
                .signInWithCredential(credentials)
                .then((response) => {
                    commit("LOGIN", {
                        email: response.user.email,
                        password: "",
                    });
                    payload.vm.$cookie.setCookie(
                        "user",
                        {
                            email: response.user.email,
                            password: "",
                        },
                        { expire: "1d" }
                    );
                    dispatch("FETCH_COINS");
                    dispatch("FETCH_BLACKLISTS");
                    //alert("Login Success");
                    createToast("Login Success", { type: 'success' })
                    payload.vm.$router.push("/");
                })
                .catch((error) => {
                    alert(error.message);
                });
        },
        async FIREABASE_LOGOUT({ commit }, payload) {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    commit("LOGOUT");
                    //alert("Logut Success");
                    createToast("Logout Success", { type: 'success' })
                    payload.vm.$router.push("/");
                    payload.vm.$cookie.removeCookie("user");
                    localStorage.removeItem('credendtials')
                })
                .catch((error) => {
                    //alert(error.message);
                    createToast(error.message, { type: 'danger' })
                });
        },
/*
        async ADD_DATA() {
            data['coins'].map((coin) => {
                database.ref("coins").push(coin)
            })   
        },
*/
        async ADD_COIN({ state, dispatch }, payload) {
            if (state.isAuthenticated) {
                database
                    .ref("coins")
                    .push(payload.coin)
                    .then((docRef) => {
                        createToast("Coin has been added successfully", { type: 'success' })
                        //alert("Coin has been added successfully");
                        payload.vm.$router.push({ name: "Home" });
                        dispatch("FETCH_COINS");
                    })
                    .catch((error) => {
                        console.error("Error adding document: ", error);
                    });
            } else {
                payload.vm.$router.push({ name: "Login" });
                createToast("You must be logged in to add new token", { type: 'danger' })
                //alert("You must be logged in to add new token");
            }
        },
        async FETCH_COINS({ state }) {
            database
                .ref("coins")
                .get()
                .then((querySnapshot) => {
                    state.coins = [];
                    if (querySnapshot.exists()) {
                        Object.keys(querySnapshot.val()).forEach((doc) => {
                            let object = querySnapshot.val()[doc]
                            object.id = doc;
                            state.coins.push(object);
                        });
                    }
                });
        },
        
        async FETCH_BLACKLISTS({ state }) {
            database
                .ref("blacklists")
                .get()
                .then((querySnapshot) => {
                    state.blacklists = [];
                    if (querySnapshot.exists()) {
                        Object.keys(querySnapshot.val()).forEach((doc) => {
                            let object = querySnapshot.val()[doc]
                            object.id = doc;
                            state.blacklists.push(object);
                        });
                    }
                });
        },
        async COUNT_VOTE({ state, dispatch }, payload) {
            if (state.isAuthenticated) {
            const coin = state.coins.find((coin) => coin.id === payload.id);
            const IsBlacklisted = checkBlacklist(
                state.blacklists,
                coin.id,
                state.user.email
            );

            if (!IsBlacklisted) {

       
                coin.votes = Number(coin.votes) + 1;
     
                
                database
                    .ref(`coins/${coin.id}`)
                    .update(coin)
                    .then(async () => {
                        let blacklist = {
                            userEmail: state.user.email,
                            coinId: coin.id,
                            expiry: new Date(
                                Date.now() + 1 * 24 * 60 * 60 * 1000
                            ).toDateString(),
                            currentIp: await publicIp.v4(),
                        };
                        dispatch("ADD_COIN_TO_BLACKLIST", blacklist);
                        //alert("You successfully voted for " + coin.symbol);
                        //createToast("You successfully voted for " + coin.fullname)
                        createToast("You successfully voted for " + coin.fullname, { type: 'success' })
                    })
                    .catch((error) => {
                        //alert(error.message);
                        createToast(error.message, { type: 'danger' })
                    });

                database
                    .ref("blacklists")
                    .get()
                    .then(async (querySnapshot) => {
                        querySnapshot.forEach(function (doc) {
                            if (doc.val()['coinId'] === coin.id) {
                                doc.ref.remove()
                            }
                        });
                        await dispatch("FETCH_BLACKLISTS");
                    });
            } else {
                //alert("Sorry you already voted this coin today");
                createToast("Sorry you already voted for " + coin.fullname, { type: 'danger' })
            }
             }  else {
              payload.vm.$router.push({ name: "Login" });
              alert("Please login to count your vote");
            }  
        },
        async ADD_COIN_TO_BLACKLIST({ dispatch }, payload) {
            database
                .ref("blacklists")
                .push(payload)
                .then(async () => {
                    await dispatch("FETCH_BLACKLISTS");
                })
                .catch((error) => {
                    console.error("Error adding document: ", error);
                });
        },
    },
});

export default store;
