<template>
  <div>
 
    <nav class="bg-white dark:bg-gray-900 shadow-sm sticky top-0 border-b dark:border-gray-700">
      <div class="max-w-7xl mx-auto px-5">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="-ml-2 mr-2 flex items-center md:hidden ">
             
              <button
                type="button"
                class="
                  inline-flex
                  items-center
                  justify-center
                  p-2
                  rounded-md
                  text-gray-400
                  hover:text-white
                  hover:bg-gray-700
                  focus:outline-none
                  focus:ring-2 focus:ring-inset focus:ring-white
                  shadow-sm
                "
                aria-controls="mobile-menu"
                @click="open = !open"
                aria-expanded="false"
                x-bind:aria-expanded="open.toString()"
              >
                <span class="sr-only">Open main menu</span>
                <svg
                  class="block h-6 w-6"
                  :class="{ block: !open }"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
                <svg
                  class="hidden h-6 w-6"
                  :class="{ block: open, hidden: !open }"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
         <router-link
              to="/"
              class="
                no-underline
                flex-shrink-0 flex
                items-center
                dark:text-white
                font-extrabold
                text-2xl
              "
            >
              {{title}}
            </router-link>
          </div>
          <div class="flex items-center">
            <div class="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                    
                   <!-- <Search/> -->

              <!-- Desktop lights switch -->
              <div
                class="
                  form-switch
                  focus-within:outline-blue
                  flex flex-col
                  justify-center
                  ml-3
                "
              >
                <input
                  type="checkbox"
                  name="light-switch"
                  id="light-switch-desktop"
                  v-model="darkMode"
                  class="light-switch sr-only"
                />
                <label class="relative" for="light-switch-desktop">
                  <span
                    class="
                      relative
                      bg-gradient-to-t
                      from-gray-100
                      to-white
                      dark:from-gray-800
                      dark:to-gray-700
                      z-10
                    "
                    aria-hidden="true"
                  ></span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 dark:text-white cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                  <span class="sr-only">Switch to light / dark version</span>
                </label>
              </div>

         <MenuItem/>


            </div>
            <div class="flex-shrink-0">
              <router-link
                v-if="!authState"
                to="Login"
                class="
                  no-underline
                  relative
                  inline-flex
                  items-center
                  px-4
                  py-2
                  ml-4
                  border border-transparent
                  font-medium
                  rounded-md
                  text-white
                  bg-green-600
                  hover:bg-green-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-offset-gray-800
                  focus:ring-green-500
                  shadow-sm
                  text-sm
                  font-bold
                "
              >
                <span>Log in</span>
              </router-link>
              <button
                @click="logout"
                v-else
                class="
                  relative
                  inline-flex
                  items-center
                  px-4
                  py-2
                  ml-4
                  border border-transparent
                  font-medium
                  rounded-md
                  text-white
                  bg-green-800
                  hover:bg-green-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-offset-gray-800
                  focus:ring-green-800
                  shadow-sm
                  text-sm
                  font-bold
                "
              >
                <span>Logout</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="md:hidden" id="mobile-menu" v-if="open">
        <MenuItem/>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuItem from "@/components/Parts/MenuItem";
import Search from "@/components/Parts/Search";

export default {
  name: "Header",
  components: { 
    MenuItem,
    Search
  },
    mounted() { 

      
       if(localStorage.getItem("dark-mode") === 'false') { 
        
        document.documentElement.classList.remove("dark");
        
      } else { 

        document.documentElement.classList.add("dark");


      }
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      darkMode: this.handleLights(),
    };
  },
  computed: {
    ...mapGetters(["authState"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("FIREABASE_LOGOUT", { vm: this });
    },
    handleLights: function () {
      const dark = localStorage.getItem("dark-mode");
      if (dark === null) {
        return true;
      } else {
        return dark === "true";
      }
    },
  },
  watch: {
    darkMode() {
      localStorage.setItem("dark-mode", this.darkMode);
      if (this.darkMode) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }

    },
  },
};
</script>

<style>
.form-switch {
}
.form-switch input[type="checkbox"].light-switch {
}
</style>
