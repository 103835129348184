import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: process.env.VUE_APP_TITLE + " - Home",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */"../views/Login.vue"),
    meta: {
      title: process.env.VUE_APP_TITLE + " - Login",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import(/* webpackChunkName: "register" */"../views/Register.vue"),
    meta: {
      title: process.env.VUE_APP_TITLE + " - Register",
    },
  },
  {
    path: "/addcoin",
    name: "AddCoin",
    component: () => import(/* webpackChunkName: "addCoin" */"../views/AddCoin.vue"),
    meta: {
      title: "fffffffffffffff",
    },
  },
  {
    path: "/coin/:coinId",
    name: "Coin",
    component: () => import(/* webpackChunkName: "coin" */"../views/Coin.vue"),
    meta: {
       title: "dffffffffff",
       description: "dfsdfdafgdgdsg"
    },
    
  },
  {
    path: "/promote",
    name: "Promote",
    component: () => import(/* webpackChunkName: "promote" */"../views/Promote.vue"),
    meta: {
      title: process.env.VUE_APP_TITLE + " - Promote",
    },
  },
  {
    path: "/contact-us",
    name: "Contact",
    component: () => import(/* webpackChunkName: "contact" */"../views/Contact.vue"),
    meta: {
      title: process.env.VUE_APP_TITLE + " - Contact Us",
    },
  },
  {
    path: "/terms-and-conditions",
    name: "Terms and Conditions",
    component: () => import(/* webpackChunkName: "terms" */"../views/Terms.vue"),
    meta: {
      title: process.env.VUE_APP_TITLE + " - Terms and Conditions",
    },
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import(/* webpackChunkName: "privacy" */"../views/Privacy.vue"),
    meta: {
      title: process.env.VUE_APP_TITLE + " - Privacy Policy",
    },
  },
  {
    path: "/dyor",
    name: "DYOR",
    component: () => import(/* webpackChunkName: "dyor" */"../views/Dyor.vue"),
    meta: {
      title: process.env.VUE_APP_TITLE + " - DYOR - Do your own Research",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
 
export default router;

router.beforeEach((to, from, next) => {
  if (to.meta.description) {
    document.description = to.meta.description;
  }
  next();
  window.scrollTo(0, 0)
});

 
 
