<template>
<div class="flex items-center justify-center">
  <div class="overflow-hidden flex">
    <input type="text" class="appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white" placeholder="Search...">
  </div>
</div>
</template>

<script>
export default {
  name: "Search",
  data() {
    return { 
    };
  },
  computed: {},
};
</script>

<style></style>
