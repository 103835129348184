<template>
<div>
  <div id="nav">
    <Header />
  </div>
  <main class="main bg-gray-200 dark:bg-gray-800 min-h-800 px-2">

    <div class="container mx-auto max-w-7xl">

    <Breadcrumbs/>

    <transition>
    <router-view />
    </transition>

    </div>


 <Footer />

  </main>
 
    </div>
 
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Breadcrumbs from "./components/Parts/Breadcrumbs.vue";


export default {

  components: {
    Header,
    Footer,
    Breadcrumbs
  },
  /*
  async created() {
    // this.$store.dispatch('ADD_DATA')
    this.$store.dispatch("FETCH_COINS");
    this.$store.dispatch("FETCH_BLACKLISTS");

    if (this.$cookie.isCookieAvailable("user")) {
      const user = this.$cookie.getCookie("user");

      this.$store.dispatch("FIREABASE_LOGIN", {
        email: user.email,
        password: user.password,
        vm: this,
      });
    }
  },
  */
   async created() {
    //this.$store.dispatch('ADD_DATA')
    this.$store.dispatch("FETCH_COINS");
    this.$store.dispatch("FETCH_BLACKLISTS");

    if (this.$cookie.isCookieAvailable("user")) {
      const user = this.$cookie.getCookie("user");
      const credentials = JSON.parse(localStorage.getItem("credentials"));
      if (!credentials) {
        this.$store.dispatch("FIREABASE_LOGIN", {
          email: user.email,
          password: user.password,
          vm: this,
        });
      } else {
        this.$store.dispatch("FIREABASE_AUTO_LOGIN_GOOGLE", {
          email: user.email,
          password: user.password,
          credentials: credentials,
          vm: this,
        });
      }
    }
  },
};
</script>

<style>
.main {
  min-height: calc(100vh - 64px);
  height: 100%;
}

</style>
