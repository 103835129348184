<template>
  <div>
    <div class="flex flex-col">
      <div class="align-middle inline-block min-w-full w-auto">
        <div class="shadow-sm overflow-hidden sm:rounded-b-lg sm:rounded-tr-lg">
 
    
          <table class="min-w-full w-auto divide-y divide-gray-200 dark:divide-gray-600">
                
            <thead
              class="
                bg-white
                dark:bg-gray-900
                cursor-pointer
              "
            >
              <tr>
                <th scope="col" class="relative px-6 py-3">
                   <input type="text" 
                v-model="search"
                class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white" 
                      placeholder="Search...">
                </th>
               
                 <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-bold
                    text-gray-900
                    dark:text-white
                    uppercase
                    tracking-wider
                  "
                >
                  Chain
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-bold
                    text-gray-900
                    dark:text-white
                    uppercase
                    tracking-wider
                  "
                >
                  24h USD
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-bold
                    text-gray-900
                    dark:text-white
                    uppercase
                    tracking-wider
                  "
                >
                  Market Cap
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-bold
                    text-gray-900
                    dark:text-white
                    uppercase
                    tracking-wider
                  "
                >
                  Launched
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-bold
                    font-medium
                    text-gray-900
                    dark:text-white
                    uppercase
                    tracking-wider
                  "
                >
                  Votes
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 dark:divide-gray-600" x-max="1">
              <tr
                class="
                  bg-white
                  hover:bg-gray-200
                  dark:bg-gray-900
                  dark:hover:bg-gray-700
                  cursor-pointer
                "
                v-for="(coin, index) in coins"
                :key="index"
                @click="
                  $router.push({
                    name: 'Coin',
                    params: { coinId: clean(coin.fullname) },
                  })
                "
              >
          
                <td
                  class="
                    sm:px-6
                    p-2
                    sm:py-2
                    overflow-ellipsis
                    text-gray-900
                    dark:text-white
                    flex
                    items-center
                  "
                >
                 <img class="mr-3 w-12 " :src="coin.image" alt="icon" /> <b>{{ coin.fullname }}  <span v-if="coin.symbol !== null" class="py-1 px-3 bg-gray-500 rounded-lg font-medium ml-3 text-white text-xs">{{ coin.symbol }} </span></b>
                </td>
                         <td
                  class="
                    sm:px-6
                    p-2
                    sm:py-2
                    overflow-ellipsis
                    text-gray-900
                    dark:text-white
                  "
                >
                  <span class="py-2 px-3 bg-gray-500 rounded-lg text-white text-xs">{{ coin.network }}</span>
                </td>
                <td
                  class="
                    sm:px-6
                    p-2
                    sm:py-2
                    overflow-ellipsis
                    text-gray-900
                    dark:text-white
                  "
                >
                  <span>-</span>
                </td>
                <td
                  class="
                    sm:px-6
                    p-2
                    sm:py-2
                    overflow-ellipsis
                    text-gray-900
                    dark:text-white
                  "
                >
                  ${{ coin.mcap }}
                </td>
                <td
                  class="
                    sm:px-6
                    p-2
                    sm:py-2
                    overflow-ellipsis
                    text-gray-900
                    dark:text-white
                  "
                >
                  <span>{{ dateToFromNowDaily(coin.launched) }} </span>
                </td>
                <td
                  class="
                    sm:px-6
                    p-2
                    sm:py-2
                    whitespace-nowrap
                    text-left
                    font-medium
                  "
                >
                  <div
                    @click.stop="
                      selectedCoinId = coin.id;
                      recaptcha = !recaptcha;
                    "
                    :class="`flex flex-row w-full items-center justify-center border border-green-500 px-6 py-2 rounded font-bold ${
                      verifiedCoins.some(
                        (verifiedCoin) => verifiedCoin.coinId === coin.id
                      )
                        ? 'bg-green-500 text-white'
                        : 'text-green-500 hover:bg-green-500 hover:text-white'
                    }`"
                  >
                   <i class="fa fa-arrow-up mr-3"></i> {{ coin.votes }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Recaptcha
      :showRecaptcha="recaptcha"
      @recaptchaVerified="recaptchaVerified"
    />
  </div>
</template>

<script>
import { ArrowNarrowUpIcon } from "@heroicons/vue/solid";
import { utils } from "../../utils/utils";
import Recaptcha from "../Recaptcha/Recaptcha.vue";
import { mapGetters } from "vuex";
import { clean } from "@/utils/helpers";
import moment from "moment";



export default {
  name: "DataTable",
  components: {
    ArrowNarrowUpIcon,
    Recaptcha,
  },
  setup () {
    const toast = () => {
        createToast('Wow, easy')
    }
    return { toast }
  },
  data() {
    return {
      recaptcha: false,
      selectedCoinId: "",
      search: '',
       items: this.coins
    };
  },
  props: ["coins"],
  computed: {
    ...mapGetters(["verifiedCoins"]),

    coins () {
      return this.coins.filter(item => {
         return item.fullname.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    }

  },
  methods: {
  dateToFromNowDaily( myDate ) {

    // get from-now for this date
    var fromNow = moment( myDate ).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment( myDate ).calendar( null, {
        // when the date is closer, specify custom values
        lastWeek: '[Last] dddd',
        lastDay:  '[Yesterday]',
        sameDay:  '[Today]',
        nextDay:  '[Tomorrow]',
        nextWeek: 'dddd',
        // when the date is further away, use from-now functionality             
        sameElse: function () {
            return "[" + fromNow + "]";
        }
    });
},
    momentFromNow: function (date) {
      return moment().to(date, "days");
    },
    moment: function (date) {
      return moment(date).format("MMMM Do YYYY");
    },
    async countVote() {
      this.$store.dispatch("COUNT_VOTE", { id: this.selectedCoinId, vm: this });
    },
    async recaptchaVerified(token) {
      let response = await utils.verifySite(token);
      if (response) {
        this.recaptcha = false;
        this.countVote();
      }
    },
    clean,
  },
};
</script>

<style></style>
